#media-player {
  display: flex;
}

#media-player .media-player-background {
  background: url("jukebox-bg.203f620b.png");
}

#media-player .media-player-title {
  background-color: #ed3636;
  width: 502px;
  height: 34px;
  margin-left: 10px;
  display: flex;
  position: relative;
}

#media-player .media-player-title .media-title {
  width: 450px;
  margin-left: 45px;
  overflow: hidden;
}

#media-player .media-player-title .media-title .line {
  background-color: #0b1c2d;
  width: 89.5%;
  height: 1px;
  position: absolute;
}

#media-player .media-player-title .media-title .line.line-1 {
  top: 13px;
}

#media-player .media-player-title .media-title .line.line-2 {
  top: 16.5px;
}

#media-player .media-player-title .media-title .line.line-3 {
  top: 20px;
}

#media-player .media-player-title .media-title .media-title-container {
  flex-wrap: wrap;
  align-content: center;
  width: 50000px;
  height: 100%;
  display: flex;
}

#media-player .media-player-title .media-title .media-title-container .media-title-text {
  color: #ed3636;
  padding-right: 30px;
  font-family: edit-undo;
  font-size: 22px;
  position: relative;
  overflow: auto;
}

#media-player .media-player-title .media-title .media-title-container .media-title-text#media-title-text-1 {
  order: 0;
}

#media-player .media-player-title .media-title .media-title-container .media-title-text#media-title-text-2 {
  order: 1;
}

#media-player .media-player-title .media-title .media-title-container .media-title-text#media-title-text-3 {
  order: 2;
}

#media-player .media-player-title .media-title .media-title-container .media-title-text#media-title-text-4 {
  order: 3;
}
/*# sourceMappingURL=repo.06bce29b.css.map */
